<nav [@animateMenu] class="navbar main-navbar on-top" [class.nav-shadow]='this.pageYPosition>0'>
    <div class="status-bar" [dir]="'Direction' | translate">
        <div class="status-content">
            <span class="datetime">{{ currentDate | date:'medium' }}</span>
            <button class="indicator" [ngClass]="{'green': isMarketOpen, 'red': !isMarketOpen}"></button>
            <button class="link-button" (click)="navigateTo('eTrade')">{{"eTrade" | translate}}</button>
            <button class="link-button" (click)="navigateTo('ultimateTrade')">{{"ultimateTrade" | translate}}</button>
            <button class="open-account-button" (click)="navigateTo('openAccount')">{{"openAccount" | translate}}</button>
            <div class="search-container">
                <input type="text" placeholder="{{ 'search' | translate }}..." [formControl]="searchControl">
                <button class="search-icon-button" (click)="search()">
                  <img src="assets/icons/search.svg" alt="Search">
                </button>
                <div class="search-results" *ngIf="filteredStocks.length > 0">
                  <ul>
                    <li *ngFor="let stock of filteredStocks.slice(0, 10)" (click)="onSelectStock(stock.isin)">
                      {{ stock.englishName }}
                    </li>
                  </ul>
                </div>
              </div>
        </div>
    </div>

    <div class="ticker-bar" [dir]="'Direction' | translate">
        <marquee>
            <ng-container *ngIf="stockNews.length > 0; else noNews">
                <span *ngFor="let stock of stockNews">
                    {{ stock.englishName }}
                    <span style="color: #000;"> {{stock.open}} {{stock.close}} </span>
                    <span [ngStyle]="{'color': stock.change >= 0 ? 'green' : 'red'}">
                        {{ stock.change }}%
                    </span> &nbsp;
                </span>
            </ng-container>
            <ng-template #noNews>No news available</ng-template>
        </marquee>
    </div>

    <div class="container" [dir]="'Direction' | translate">
        <a class="navbar-brand text-light" [routerLink]="'inicio'">
            <img src='assets/images/pioLogo.png' width='25' />
        </a>
        <div class="nav-center-wrapper">
            <ul ngbNav #nav="ngbNav" class="menu-ul">
                
                <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                    <a ngbNavLink (click)='scroll("banner")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item1" | translate}}</span></a>
                    <ng-template ngbNavContent>First content</ng-template>
                </li>
                <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                    <a ngbNavLink (click)='scroll("news")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item3" | translate}}</span></a>
                    <ng-template ngbNavContent>First content</ng-template>
                </li>
                <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_experience", "menu", "click")'>
                    <a ngbNavLink (click)='scroll("trading-rules")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item4" | translate}}</span></a>
                    <ng-template ngbNavContent>Second content</ng-template>
                </li>
                <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                    <a ngbNavLink (click)='scroll("market-statistics")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item5" | translate}}</span></a>
                    <ng-template ngbNavContent>Second content</ng-template>
                </li>
                <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                    <a ngbNavLink (click)='scroll("market-watch")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item2" | translate}}</span></a>
                    <ng-template ngbNavContent>First content</ng-template>
                </li>
                <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
                    <a ngbNavLink (click)='scroll("contact-us")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item6" | translate}}</span></a>
                    <ng-template ngbNavContent>Second content</ng-template>
                </li>
            </ul>
        </div>
        <div ngbDropdown class="d-inline-block">
            <div class="language-container  language-container-full-nav" id="dropdownForm1" ngbDropdownToggle>
                <img *ngIf='languageFormControl.value == "en"' width="20px" src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                <span *ngIf='languageFormControl.value == "en"' class="flag-text"></span>
                <img *ngIf='languageFormControl.value == "ar"' width="20px" src="https://www.worldometers.info/img/flags/eg-flag.gif" alt="Arabic">
                <span *ngIf='languageFormControl.value == "ar"' class="flag-text"></span>
                <svg class='arrow-language' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                <div (click)='changeLanguage("ar")' style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;' class="language-container language-container-selector active" [class.active]='languageFormControl.value == "ar"'>
                    <img width="20px" src="https://www.worldometers.info/img/flags/eg-flag.gif" alt="Arabic">
                    <span class="flag-text"> العربية </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </div>
                <div (click)='changeLanguage("en")' style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;" class="language-container language-container-selector" [class.active]='languageFormControl.value == "en"'>
                    <img width="20px" src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                    <span class="flag-text">English</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="menu-wrapper">
            <div [class.animate]='responsiveMenuVisible' (click)='responsiveMenuVisible = !responsiveMenuVisible' class="hamburger-menu"></div>
        </div>
        <div class="menu-responsive" [ngStyle]="{'pointer-events': !responsiveMenuVisible ? 'none' : '' }">
            <aside [class.aside-show]='responsiveMenuVisible' [class.nav-shadow]='this.pageYPosition>0' class="on-top">
                <nav>
                    <ol>
                        <li (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                            <a (click)='scroll("banner")'>
                                <span></span> {{"Header.Item1" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_experience", "menu", "click")'>
                            <a (click)='scroll("market-statistics")'>
                                <span></span> {{"Header.Item5" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                            <a (click)='scroll("news")'>
                                <span></span> {{"Header.Item3" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                            <a (click)='scroll("market-watch")'>
                                <span></span> {{"Header.Item2" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
                            <a (click)='scroll("trading-rules")'>
                                <span></span> {{"Header.Item4" | translate}}
                            </a>
                        </li>
                        <li  (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
                            <a (click)='scroll("contact-us")'>
                                <span></span> {{"Header.Item6" | translate}}</a>
                            
                        </li>
                        <li>
                            <div class="language-container">
                                <div (click)='changeLanguage("ar")' style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;' class="language-container language-container-selector active" [class.active]='languageFormControl.value == "ar"'>
                                    <img src="https://www.worldometers.info/img/flags/eg-flag.gif" alt="Arabic">
                                    <span class="flag-text" style="direction: rtl;">العربية</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                    </svg>
                                </div>
                                <div (click)='changeLanguage("en")' style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;" class="language-container language-container-selector" [class.active]='languageFormControl.value == "en"'>
                                    <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                                    <span class="flag-text">English</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                    </svg>
                                </div>
                            </div>
                        </li>
                    </ol>
                </nav>
            </aside>
        </div>
    </div>
</nav>
