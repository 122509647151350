<section id="market-watch">
    <div class="jobs" [dir]="'Direction' | translate">
      <div class="container" data-aos="fade-up" data-aos-duration="1000">
        <div class="container" [dir]="'Direction' | translate">
          <h3 class="section-title">{{ "Header.Item2" | translate }}</h3>
        </div>
        <div class="jobs-inner">
          <div class="market-watch mx-2 my-5">
            <div class="text-center">
              <h2>{{ borsaDate | date }}</h2>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th (click)="sortData('englishName')">{{ "Name" | translate }}<span *ngIf="currentSortColumn === 'englishName'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('symbol')">{{ "symbol" | translate }} <span *ngIf="currentSortColumn === 'symbol'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('open')">{{ "open" | translate }} <span *ngIf="currentSortColumn === 'open'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('close')">{{ "close" | translate }} <span *ngIf="currentSortColumn === 'close'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('change')">{{ "change" | translate }} <span *ngIf="currentSortColumn === 'change'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    
                    <th (click)="sortData('lastPrice')">{{ "lastPrice" | translate }} <span *ngIf="currentSortColumn === 'lastPrice'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('highBidPrice')"> {{ "highBidPrice" | translate }}<span *ngIf="currentSortColumn === 'highBidPrice'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('lowAskPrice')">{{ "lowAskPrice" | translate }} <span *ngIf="currentSortColumn === 'lowAskPrice'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('tradesCount')">{{ "tradesCount" | translate }} <span *ngIf="currentSortColumn === 'tradesCount'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('askVolume')"> {{ "askVolume" | translate }}<span *ngIf="currentSortColumn === 'askVolume'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('bidVolume')">{{ "bidVolume" | translate }} <span *ngIf="currentSortColumn === 'bidVolume'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    
                    <th (click)="sortData('value')">{{ "value" | translate }} <span *ngIf="currentSortColumn === 'value'">{{ isAscending ? '▲' : '▼' }}</span></th>
                    <th (click)="sortData('volume')">{{ "volume" | translate }} <span *ngIf="currentSortColumn === 'volume'">{{ isAscending ? '▲' : '▼' }}</span></th>
                                      
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let stock of displayedStocks" (click)="navigateToDetails(stock.isin)">
                    <td>{{ stock.englishName }}</td>
                    <td>{{ stock.symbol }}</td>
                    <td>{{ stock.open }}</td>
                    <td>{{ stock.close }}</td>
                    <td [ngClass]="{'text-success': stock.change > 0, 'text-danger': stock.change < 0}">{{ stock.change }}</td>
                    <td>{{ stock.lastPrice }}</td>
                    <td>{{ stock.highBidPrice }}</td>
                    <td>{{ stock.lowAskPrice }}</td>
                    <td>{{ stock.tradesCount }}</td>
                    <td>{{ stock.askVolume | number:'1.0-0' }}</td>
                    <td>{{ stock.bidVolume  | number:'1.0-0'}}</td>
                    
                    <td>{{ stock.value| number:'1.0-0' }}</td>
                    <td>{{ stock.volume | number:'1.0-0' }}</td>
                    
                    
                  </tr>
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation">
                <ul class="pagination justify-content-center">
                  <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" (click)="setPage(currentPage - 1)" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li *ngFor="let page of getPaginationPages()" class="page-item" [class.active]="currentPage === page">
                    <a class="page-link" (click)="setPage(page)">{{ page }}</a>
                  </li>
                  <li class="page-item" [class.disabled]="currentPage === totalPages">
                    <a class="page-link" (click)="setPage(currentPage + 1)" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  