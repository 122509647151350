<main>
    <app-banner></app-banner>
    <!-- <app-jobs></app-jobs> -->
    <app-stock-market></app-stock-market>
    <app-market-summary></app-market-summary>
    <app-market-watch></app-market-watch>
    <app-about></app-about>
    <app-proyects></app-proyects>
    
    <app-more-proyects></app-more-proyects>
    
    
    
    
    <app-sectors></app-sectors>
   
    <app-news></app-news>
    <app-contact-us></app-contact-us>
    <app-contact></app-contact>

    
</main>

