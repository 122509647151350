import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  // language: "ar" | "en";

  constructor(
    public translateService: TranslateService,
    private location: Location,
  ) {}

  initLanguage(){
    // this.translateService.addLangs(["en", "ar"])
    // let language = navigator.language || (navigator as any).userLanguage;
    // language = language.split("-").includes("ar") ? "ar" : "en"
    // this.translateService.setDefaultLang(language)
    let language = localStorage.getItem("language") ?? "en"
    this.translateService.setDefaultLang(language)

    // Change the URL without navigate:
    // this.location.go(language)

    // this.language=language
  }

  changeLanguage(language){
    this.translateService.setDefaultLang(language)
    localStorage.setItem("language", language)
    // this.location.go(language)
    // this.language=language
  }
}
