<section class="section contact-us" id="contact-us" [dir]="'Direction' | translate">
  <div class="container" data-aos="fade-up" data-aos-duration="1000">
    <div class="section-box">
      <div>
        <h3 class="section-title">
          {{ 'ContactUs.Title' | translate }}
        </h3>
      </div>
      <div class="row" data-aos="fade-up" data-aos-duration="1000">
        <div class="col-lg-6">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12">
                <input matInput class="form form-control mb-2" formControlName="name" type="text" placeholder="{{ 'ContactUs.YourName' | translate }}" />
                <div *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)" class="text-danger">
                  {{ 'ContactUs.RequiredField' | translate }}
                </div>
              </div>
              <div class="col-12">
                <textarea matInput class="form form-control mb-2" formControlName="message" placeholder="{{ 'ContactUs.YourMessage' | translate }}" rows="12"></textarea>
                <div *ngIf="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)" class="text-danger">
                  {{ 'ContactUs.RequiredField' | translate }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input matInput class="form form-control mb-2" formControlName="email" type="text" placeholder="{{ 'ContactUs.Email' | translate }}" />
                <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)" class="text-danger">
                  {{ 'ContactUs.RequiredField' | translate }}
                </div>
              </div>
              <div class="col-md-6">
                <input matInput class="form form-control mb-2" formControlName="phone" type="text" placeholder="{{ 'ContactUs.Phone' | translate }}" />
                <div *ngIf="contactForm.get('phone').invalid && (contactForm.get('phone').dirty || contactForm.get('phone').touched)" class="text-danger">
                  {{ 'ContactUs.RequiredField' | translate }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="dropdown w-100">
                  <mat-label>{{ 'ContactUs.Country' | translate }}</mat-label>
                  <mat-select formControlName="country">
                    <mat-option *ngFor="let country of (cities | keyvalue)" [value]="country.key">{{ country.key }}</mat-option>
                  </mat-select>
                  <div *ngIf="contactForm.get('country').invalid && (contactForm.get('country').dirty || contactForm.get('country').touched)" class="text-danger">
                    {{ 'ContactUs.RequiredField' | translate }}
                  </div>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="dropdown w-100">
                  <mat-label>{{ 'ContactUs.City' | translate }}</mat-label>
                  <mat-select formControlName="city">
                    <mat-option *ngFor="let city of cityOptions" [value]="city">{{ city }}</mat-option>
                  </mat-select>
                  <div *ngIf="contactForm.get('city').invalid && (contactForm.get('city').dirty || contactForm.get('city').touched)" class="text-danger">
                    {{ 'ContactUs.RequiredField' | translate }}
                  </div>
                </mat-form-field>
              </div>
            </div>
            <button type="submit" class="mx-auto btn btn-primary" [disabled]="contactForm.invalid">{{ 'ContactUs.SubmitMessage' | translate }}</button>
          </form>
          <div *ngIf="message" class="mt-3 alert" [ngClass]="messageClass">{{ message | translate}}</div>
        </div>

        <div class="col-lg-6">
          <div class="contact-info">
            <h4>{{ 'ContactUs.ContactInformation' | translate }}</h4>
            <p><strong>{{ 'ContactUs.Address' | translate }}</strong> {{ 'ContactUs.Location' | translate }}</p>
            <p><strong>{{ 'ContactUs.Hotline' | translate }}</strong> 19973</p>
            <p><strong>{{ 'ContactUs.EmailAddress' | translate }}</strong> helpdesk&#64;pioneers-securities.com</p>
            <p><strong>{{ 'ContactUs.PhoneNumber' | translate }}</strong> 202-24190126</p>
          </div>
          <div class="map mt-3">
            <h4>{{ 'ContactUs.OurLocation' | translate }}</h4>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3452.025116393362!2d31.33142618046898!3d30.093467010177545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145815114875a8ff%3A0xe3df564430059452!2zUGlvbmVlcnMgc2VjdXJpdGllcyDYqNin2YrZiNmG2YrYsdiyINmE2KrYr9in2YjZhCDYp9mE2KfZiNix2KfZgiDYp9mE2YXYp9mE2YrYqQ!5e0!3m2!1sen!2seg!4v1720950282800!5m2!1sen!2seg"
              width="100%"
              height="250"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
