<div class="empty-space">

</div>
<div class="jobs ">
  <div class="container" data-aos="fade-up" data-aos-duration="1000">
    <div class="jobs-inner ">
      <div class="row mx-2">
        <div class="col-6 about-details ">
          <h2>About Pioneers</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam nihil cupiditate earum non odit illum, officiis asperiores quia perferendis atque repellendus quae qui alias in aspernatur vitae veniam distinctio placeat.</p>
          <a href="#">Read More</a>
        </div>
        <div class="col-6 about-img">
            <img src="assets/images/vision3.jpeg" class="img-fluid">
        </div>
      </div>
    </div>

  </div>
</div>

