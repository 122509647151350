<div class="empty-space"></div>
<div class="jobs" [dir]="'Direction' | translate">
    <div class="container">
        <div class="container">
            <h3 class="section-title">
               {{"CoreValues.Title" | translate}}
            </h3>
        </div>

        <div class="jobs-inner">
            <div class="row space-row">
                <div class="col-3 w-50 h-25 p-2 position-relative">
                    <img [src]="'Integrity.Image' | translate" class="img-fluid border rounded" alt="{{ 'Integrity' | translate }}">
                </div>
                <div class="col-3 w-50 h-25 p-2">
                    <img [src]="'Loyalty.Image' | translate" class="img-fluid border rounded" alt="{{ 'Loyalty' | translate }}">
                </div>
                <div class="col-3 w-50 h-25 p-2">
                    <img [src]="'Creativity.Image' | translate" class="img-fluid border rounded" alt="{{ 'Creativity' | translate }}">
                </div>
                <div class="col-3 w-50 h-25 p-2">
                    <img [src]="'Passion.Image' | translate" class="img-fluid border rounded" alt="{{ 'Passion' | translate }}">
                </div>
            </div>
            <p class="space-row">{{ "CoreValues.Description" | translate }}</p>
        </div>
    </div>
</div>
 