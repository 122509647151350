<div class="empty-space"></div>
<div class="jobs" [dir]="'Direction' | translate">
    <div class="container">
        <div class="jobs-inner">
            <div class="row mx-2">
                <div class="col-md-6">
                    <h2>{{ "WhoWeAre.Title" | translate }}</h2>
                    <p>{{ "WhoWeAre.Content" | translate }}</p>
                </div>
                <div class="col-md-6">
                    <img [src]="'WhoWeAre.Image' | translate" class="img-fluid" alt="{{ 'WhoWeAre.Title' | translate }}">
                </div>
            </div>
            <div class="row mt-4 mx-2">
                <div class="col-md-6">
                    <img [src]="'Mission.Image' | translate" class="img-fluid" alt="{{ 'Mission.Title' | translate }}">
                </div>
                <div class="col-md-6">
                    <h2>{{ "Mission.Title" | translate }}</h2>
                    <p>{{ "Mission.Content" | translate }}</p>
                </div>
            </div>
            <div class="row mt-4 mx-2">
                <div class="col-md-6">
                    <h2>{{ "Vision.Title" | translate }}</h2>
                    <p>{{ "Vision.Content" | translate }}</p>
                </div>
                <div class="col-md-6">
                    <img [src]="'Vision.Image' | translate" class="img-fluid" alt="{{ 'Vision.Title' | translate }}">
                </div>
            </div>
        </div>
    </div>
</div>
